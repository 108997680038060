import React, {useState} from 'react'
//import styled from 'styled-components'
import './navbar.scss'
import '../../index.css'
import {CgMenuGridO} from 'react-icons/cg'
import LOGO from '../../assets/logo-nav.jpg'
import {SiConsul}  from 'react-icons/si'
import {MdClose} from 'react-icons/md'
import { Link } from 'react-router-dom'



const Navbar = () => {

  const [active, setActive] = useState('navBarMenu');

  const showNavBar = () => {
    setActive('navBarMenu showNavBar');
  }
  const removeNavBar = () => {
    setActive('navBarMenu');
  }

  return (
    <div className='navBar'>
        <div className="navBarOne">
          <div>
            <SiConsul className='icon'/>
          </div>
          <div className='title'>
             <h1>Help Africa integrated development organization (HAIDO)</h1>
          </div>
          <div className="atb">
            <h3>DONATE</h3>
            <span></span>
          </div>

        </div>

       <div className="navBarTwo">
         <div className="LogoDiv">
            <img src={LOGO}  alt="" className='Logo'/>
            <h1>HAIDO</h1>
         </div>

         <div id='listbar' className={active}>
          <MdClose onClick={removeNavBar} className='icon'/>
          <ul className="menu flex">
            
             <li  className="listItem">
                 <Link to='/home'>
                    <span>Home</span>
                 </Link>
                 <h2>|</h2>
              </li>
             <li  className="listItem">
                 <Link to='/about'>
                     <span>About</span>
                  </Link>
                  <h2>|</h2>
             </li>
             <li  className="listItem">
                 <Link to='/galary'>
                  <span>Gallary</span>
                  </Link>
                  <h2>|</h2>
             </li>
             <li  className="listItem">
                 <Link to='/events'>
                  <span>Events</span>
                 </Link>
                 <h2>|</h2>
             </li>
             <li  className="listItem">
                 <Link to='/news'>
                  <span>News</span>
                 </Link>
                 <h2>|</h2>
             </li>
          </ul>
          <button  className="flex btnOne"><Link to='/contact'>Contact</Link></button>
         </div>
         <button className="flex btnTwo"><Link to='/contact'>Contact</Link></button>

         <div  className="toggleIcon">
            <CgMenuGridO onClick={showNavBar} className='icon'/>
         </div>

        </div>

      </div>
  )
}

export default Navbar;

