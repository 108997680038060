import React from 'react'
import './galary.scss'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import Img1 from '../../assets/haido13.jpg'
import Img2 from '../../assets/haido18.jpg'
import Img3 from '../../assets/haido3.jpg'
import Img4 from '../../assets/haido4.jpg'
import Img5 from '../../assets/haido5.jpg'
import Img6 from '../../assets/haido6.jpg'
import Img7 from '../../assets/haido7.jpg'
import Img8 from '../../assets/haido8.jpg'
import Img9 from '../../assets/haido9.jpg'
import Img10 from '../../assets/haido10.jpg'
import Img11 from '../../assets/haido11.jpg'
import Img12 from '../../assets/haido12.jpg'
import Img13 from '../../assets/haido01.jpg'
import Img14 from '../../assets/haido02.jpg'
import Img15 from '../../assets/haido03.jpg'
import Img16 from '../../assets/haido04.jpg'

const data = [
  {
    id: 1,
    img1: Img1,
    alt: 'image1',
    descriptionTitle1: 'Description title',
    description1: `description`,
  },
  {
    id: 2,
    img1: Img2,
    alt: 'image2',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 3,
    img1: Img3,
    alt: 'image3',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 4,
    img1: Img4,
    alt: 'image4',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 5,
    img1: Img5,
    alt: 'image5',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 6,
    img1: Img6,
    alt: 'image6',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 7,
    img1: Img7,
    alt: 'image7',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 8,
    img1: Img8,
    alt: 'image8',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 9,
    img1: Img9,
    alt: 'image9',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 10,
    img1: Img10,
    alt: 'image10',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 11,
    img1: Img11,
    alt: 'image11',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 12,
    img1: Img12,
    alt: 'image12',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 13,
    img1: Img13,
    alt: 'image13',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 14,
    img1: Img14,
    alt: 'image14',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 15,
    img1: Img15,
    alt: 'image16',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
  {
    id: 16,
    img1: Img16,
    alt: 'image16',
    descriptionTitle1: 'Description title',
    description1: 'description',
  },
]
  
const Galary = () => {
  return (
    <>
    <Navbar/>
    <div className='galary-container'>
       <div className='galary'>
        {
          data.map(({id, img1, alt, descriptionTitle1, description1}) =>{
            return(
            <figure key={id}>
              <img className='galary-img' src={img1} alt={alt}/>
              <div className='description'>
                <figcaption>Fig.{id} - picture</figcaption>
                <h4>{descriptionTitle1}</h4>
                <span>{description1}</span>
              </div>
            </figure>
            )
          }
          )
        }
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default Galary
