import React from 'react'
import './latestnews.scss'
import img1 from '../../assets/haido3.jpg'
import img2 from '../../assets/haido4.jpg'
import img3 from '../../assets/haido11.jpg'
import { Link } from 'react-router-dom'

const LatestNews = () => {
  return (
    <div className='latest-container'>
      <div className='latest-head'>
        <span></span>
        <h1>Letest Update</h1>
      </div>
      <div className='letest-latest'>
        <div className='latest-content'>
            <img className='latest-img' src={img1} alt=''/>
            <div className='latest-text'>
              <h4>PRESS RELEAS</h4>
              <small>15th December 2023</small>
              <h2>Emergency Food delivery</h2>
              <p>
                </p>
            </div>
        </div>
        <div className='latest-content'>
            <img className='latest-img' src={img2} alt=''/>
            <div className='latest-text'>
              <h4>PRESS RELEAS</h4>
              <small>15th December 2023</small>
              <h2>Emergency Food delivery</h2>
              <p>
                </p>
            </div>
        </div>
        <div className='latest-content'>
            <img className='latest-img' src={img3} alt=''/>
            <div className='latest-text'>
              <h4>PRESS RELEAS</h4>
              <small>15th December 2023</small>
              <h2>Emergency Food delivery</h2>
              <p>
                </p>
            </div>
        </div>
      </div>
      <button className='latest-btn'><Link to='/news'>READ MORE<span>&#8594;</span></Link></button>
    </div>
  )
}

export default LatestNews
