import React  from "react";
import './contact.scss';
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {TbBrandTelegram} from 'react-icons/tb'
import  { useRef } from 'react';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
//import emailjs from 'emailjs-com';

const Contact = () => {
   const form = useRef();
     
   /*const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_nkh7y8i', 'template_q2vilhh', form.current, 'xvZhaO3sgHq4bojk9')
      
    e.target.reset()
  };*/
    return (
        <div>
        <Navbar/>
        <div className="contact">
            <h3>Help Africa Integrity Development Organization</h3>
            <h2>Contact</h2>

            <div className="contact_container">
                <div className="contact_options">
                    <article className="contact_option">
                        <MdOutlineEmail className="contact_option-icon"/>
                        <h4>Email</h4>
                        <h5>info@haido-ethiopia.org</h5>
                        <a href="mailto:info@haido-ethiopia.org" rel="noopener noreferrer" target="_blank">Send a message</a>
                    </article>
                    <article className="contact_option">
                        <RiMessengerLine className="contact_option-icon"/>
                        <h4>Messanger</h4>
                        <h5>HAIDO</h5>
                        <a href="https://m.me/" rel="noopener noreferrer"  target="_blank">Send a message</a>
                    </article>
                    <article className="contact_option">
                        <TbBrandTelegram className="contact_option-icon"/>
                        <h4>Telegram</h4>
                        <h5>@HAIDO</h5>
                        <a href="https://t.me/" rel="noopener noreferrer"  target="_blank">Send a message</a>
                    </article>
                </div>
                {/*.....END OF CONTACT OPTION.........*/}
                <form ref={form} >
                  <input type="text" name="name" placeholder="Your Full Name" required/>
                  <input type="email" name="email" placeholder="Your Email" required/>
                  <textarea name="message" rows="7" placeholder="Your Message" required></textarea>
                  <button type="submit" className="btn btn-primary">Send Message</button>
                </form>
            </div>

        </div>
        <Footer/>
        </div>
    )
}

export default Contact;