import React from 'react'
import img1 from '../../assets/haido1.jpg'
import img2 from '../../assets/haido2.jpg'
import img3 from '../../assets/haido3.jpg'
import img4 from '../../assets/haido4.jpg'
import img5 from '../../assets/haido5.jpg'
import img6 from '../../assets/haido6.jpg'
import img7 from '../../assets/haido7.jpg'
import img8 from '../../assets/haido8.jpg'
import img9 from '../../assets/haido9.jpg'
import img10 from '../../assets/haido10.jpg'
import img11 from '../../assets/haido11.jpg'
import img12 from '../../assets/haido12.jpg'
import img13 from '../../assets/haido13.jpg'
import img14 from '../../assets/haido14.jpg'
import img15 from '../../assets/haido15.jpg'
import img16 from '../../assets/haido16.jpg'
import img17 from '../../assets/haido17.jpg'
import img18 from '../../assets/haido18.jpg'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import './news.scss'

const data = [
  {
    id:1,
    img:img1,
    alt:'image1',
    PRESS: 'PRESS RELEAS',
    date: '15th December 2023',
    aboutnew: `Lorem ipsum dolor sit amet, 
            consectetur adipiscing elit, 
            sed do eiusmod`,
    news: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. `,
    morenews: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. Duis aute irure dolor in reprehenderit 
          in voluptate velit esse cillum dolore eu fugiat 
           nulla pariatur.
           Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation 
                 ullamco laboris nisi ut aliquip ex ea commodo 
                consequat. Duis aute irure dolor in reprehenderit 
                in voluptate velit esse cillum dolore eu fugiat 
                 nulla pariatur.`,
    image: img2,
  },
  {
    id:2,
    img:img3,
    alt:'image1',
    PRESS: 'PRESS RELEAS',
    date: '15th December 2023',
    aboutnew: `Lorem ipsum dolor sit amet, 
            consectetur adipiscing elit, 
            sed do eiusmod`,
    news: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. 
          `,
    morenews: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. Duis aute irure dolor in reprehenderit 
          in voluptate velit esse cillum dolore eu fugiat 
           nulla pariatur.
           Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation 
                 ullamco laboris nisi ut aliquip ex ea commodo 
                consequat. Duis aute irure dolor in reprehenderit 
                in voluptate velit esse cillum dolore eu fugiat 
                 nulla pariatur.`,
    image: img4,
  },
  {
    id:3,
    img:img5,
    alt:'image1',
    PRESS: 'PRESS RELEAS',
    date: '15th December 2023',
    aboutnew: `Lorem ipsum dolor sit amet, 
            consectetur adipiscing elit, 
            sed do eiusmod`,
    news: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat.`,
    morenews: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. Duis aute irure dolor in reprehenderit 
          in voluptate velit esse cillum dolore eu fugiat 
           nulla pariatur.
           Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation 
                 ullamco laboris nisi ut aliquip ex ea commodo 
                consequat. Duis aute irure dolor in reprehenderit 
                in voluptate velit esse cillum dolore eu fugiat 
                 nulla pariatur.`,
    image: img6,
  },
  {
    id:4,
    img:img7,
    alt:'image1',
    PRESS: 'PRESS RELEAS',
    date: '15th December 2023',
    aboutnew: `Lorem ipsum dolor sit amet, 
            consectetur adipiscing elit, 
            sed do eiusmod`,
    news: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. `,
    morenews: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. Duis aute irure dolor in reprehenderit 
          in voluptate velit esse cillum dolore eu fugiat 
           nulla pariatur.
           Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation 
                 ullamco laboris nisi ut aliquip ex ea commodo 
                consequat. Duis aute irure dolor in reprehenderit 
                in voluptate velit esse cillum dolore eu fugiat 
                 nulla pariatur.`,
    image: img8,
  },
  {
    id:5,
    img:img9,
    alt:'image1',
    PRESS: 'PRESS RELEAS',
    date: '15th December 2023',
    aboutnew: `Lorem ipsum dolor sit amet, 
            consectetur adipiscing elit, 
            sed do eiusmod`,
    news: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. `,
    morenews: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. Duis aute irure dolor in reprehenderit 
          in voluptate velit esse cillum dolore eu fugiat 
           nulla pariatur.
           Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation 
                 ullamco laboris nisi ut aliquip ex ea commodo 
                consequat. Duis aute irure dolor in reprehenderit 
                in voluptate velit esse cillum dolore eu fugiat 
                 nulla pariatur.`,
    image: img10,
  },
  {
    id:6,
    img:img11,
    alt:'image1',
    PRESS: 'PRESS RELEAS',
    date: '15th December 2023',
    aboutnew: `Lorem ipsum dolor sit amet, 
            consectetur adipiscing elit, 
            sed do eiusmod`,
    news: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. `,
    morenews: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. Duis aute irure dolor in reprehenderit 
          in voluptate velit esse cillum dolore eu fugiat 
           nulla pariatur.
           Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation 
                 ullamco laboris nisi ut aliquip ex ea commodo 
                consequat. Duis aute irure dolor in reprehenderit 
                in voluptate velit esse cillum dolore eu fugiat 
                 nulla pariatur.`,
    image: img12,
  },
  {
    id:7,
    img:img13,
    alt:'image1',
    PRESS: 'PRESS RELEAS',
    date: '15th December 2023',
    aboutnew: `Lorem ipsum dolor sit amet, 
            consectetur adipiscing elit, 
            sed do eiusmod`,
    news: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat.`,
    morenews: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. Duis aute irure dolor in reprehenderit 
          in voluptate velit esse cillum dolore eu fugiat 
           nulla pariatur.
           Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation 
                 ullamco laboris nisi ut aliquip ex ea commodo 
                consequat. Duis aute irure dolor in reprehenderit 
                in voluptate velit esse cillum dolore eu fugiat 
                 nulla pariatur.`,
    image: img14,
  },
  {
    id:8,
    img:img15,
    alt:'image1',
    PRESS: 'PRESS RELEAS',
    date: '15th December 2023',
    aboutnew: `Lorem ipsum dolor sit amet, 
            consectetur adipiscing elit, 
            sed do eiusmod`,
    news: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. `,
    morenews: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. Duis aute irure dolor in reprehenderit 
          in voluptate velit esse cillum dolore eu fugiat 
           nulla pariatur.
           Lorem ipsum dolor sit amet, consectetur 
                adipiscing elit, sed do eiusmod tempor 
                incididunt ut labore et dolore magna aliqua. 
                Ut enim ad minim veniam, quis nostrud exercitation 
                 ullamco laboris nisi ut aliquip ex ea commodo 
                consequat. Duis aute irure dolor in reprehenderit 
                in voluptate velit esse cillum dolore eu fugiat 
                 nulla pariatur.`,
    image: img16,
  },
  {
    id:9,
    img:img17,
    alt:'image1',
    PRESS: 'PRESS RELEAS',
    date: '15th December 2023',
    aboutnew: `Lorem ipsum dolor sit amet, 
            consectetur adipiscing elit, 
            sed do eiusmod`,
    news: `Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. `,
    morenews: `Lorem ipsum dolor sit amet, consectetur 
    adipiscing elit, sed do eiusmod tempor 
    incididunt ut labore et dolore magna aliqua. 
    Ut enim ad minim veniam, quis nostrud exercitation 
     ullamco laboris nisi ut aliquip ex ea commodo 
    consequat. Duis aute irure dolor in reprehenderit 
    in voluptate velit esse cillum dolore eu fugiat 
     nulla pariatur.
     Lorem ipsum dolor sit amet, consectetur 
          adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua. 
          Ut enim ad minim veniam, quis nostrud exercitation 
           ullamco laboris nisi ut aliquip ex ea commodo 
          consequat. Duis aute irure dolor in reprehenderit 
          in voluptate velit esse cillum dolore eu fugiat 
           nulla pariatur.`,
  image: img18,
  },
]

const News = () => {
  return (
    <>
    <Navbar/>
    <div className='news-container'>
      <div className='news-head'>
        <span></span>
        <h1>News</h1>
      </div>
      <div className='letest-news'>
        <p>currently no news</p>
        {/*{ 
         data.map(({id, img, alt, PRESS, date, aboutnew, news, morenews, image})=>{
          return(
            <div key={id} className='news-content'>
            <img className='news-img' src={img} alt={alt}/>
            <div className='news-text'>
              <h4>{PRESS}</h4>
              <small>{date}</small>
              <h2>{aboutnew}</h2>
              <p>{news}</p>
            </div>
            <details>
              <summary>READ MORE</summary>
              <p>{morenews}</p>
              <img className='more-img' src={image} alt={alt}/>
            </details>
        </div>
          )
         })}*/}

        </div>
       
      </div>
      <Footer/>
      </>
  )
}

export default News;
