import React from 'react'
import './about.scss'
import {FaBook} from 'react-icons/fa'
import {FaGlobeAfrica} from 'react-icons/fa'
import {FaPencilAlt} from 'react-icons/fa'
import img from '../../assets/logo.jpg'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

const About = () => {
  return (
    <>
    <Navbar/>
    <div className='container-about'>
       <div className='background'>
         <div className='about'>
         <h1>About Us</h1>
         <h2>HAIDO Organizational background</h2>
         <p>Help Africa integrated development organization (HAIDO) is an indigenous, Non-Governmental, humanitarian organization established by experienced volunteer’s humanitarian workers in Afar regional state of Ethiopia in 2019. HAIDO officially registered in 2021 under the<span> FDRE AGENCY FOR CIVIL SOCIETY ORGANIZATIONS/ACSO/ </span>with RN 5907 to work in all regional state of Ethiopia to assist natural and manmade disaster including, Conflict, flood and drought affected people in all regional states of Ethiopia.
            In 2021, HAIDO started working in Afar zone two Regional state of Ethiopia. The main focus was to assist drought, conflict and flood affected people through provision of food, core relief items, provision of effective and efficient basic socio economic services, improving quality water supply, primary education  and enhancing pastoral and agro pastoral communities through provision of farm tools, seeds, cattle and Goats. HAIDO Within the coming few years planed to grow an increasing effort to reach out of most vulnerable communities in the areas were conflict, flooding and drought affected areas of all zones in Afar
          </p>
         </div>
        </div>
      <div className='card-container'>
         <div className='implimentation'>
            <img src={img} alt=''/>
            <div className='implimentation-text'>
              <h2>IMPLIMENTATION APROACH</h2>
              <ul>
               <li><span>HAIDO</span> will monitor the project quality deliverables as per the given work specifications to achieve project objectives. HAIDO have experienced local expertise in designing and supervision and will regularly monitor and evaluate the implementation of the project. Field visits conducted to monitor project activities against the timeline and outcomes of the project.</li>
               <li><span>HAIDO</span> will set a complaint mechanism in each satellite offices and in which the beneficiaries are consulted by local staffs from the communities throughout  project implementation HAIDO management teams will take immediate measure action where there is any problem happens during implementation.</li>
               <li><span>HAIDO</span> will remain committed to the core principles embedded in Partnership, which on mutual respect, transparency, shared responsibilities. HAIDO management teams will also keep the practice of accountability and transparency that allows the mutual trust and cooperation with Governmental concerned bodies and project donors throughout the project implementation process.</li>
              <li><span>HAIDO</span> will strengthen its support departments such as finance, logistics and human resource and administrative functions to ensure that compliance to the host communities and IDPs assistance program policies and procedure. </li>
               <li>In order for the project to be successful, the project management will take in to account the soft skills necessary within HAIDO, the available resources, logistics, and vehicles to support the project. This will help to deliver the agreed commitment on time and to involve the very professional and technically experienced project staff members from HAIDO, and meet project targets.</li>
              </ul>
            </div>
         </div>
         <div className='mission-vision-achivment'>
            <div className='vission'>
            <FaBook className='icon'/>
            <h2>Vission</h2>
            Help Africa integrated development organisation-HAIDO works in Africa to save lives of vulnerable people through provisionof basic needs, tackle poverty, and achieve social justice.
                </div>
            <div className='mission'>
              <FaGlobeAfrica className='icon'/>
               <h2>Mission</h2>
               <ul>
                <li>To Address Basic need to disaster affected people everywhere in Africa  through provision of basic humanitarian service</li>
                <li>To overcome endless poverty </li>
                
               </ul>
            </div>
            <div className='achivment'>
              <FaPencilAlt className='icon'/>
              <h2>Our Achievements</h2>
              <ul>
                <li>Currently, HAIDO has more than 62 national employees working  in Afar Semera and 2 satellite offices located in zone two koneba and Berahalle in Afar regional state of Ethiopia.</li>
                <li>HAIDO is Led by 5 elected board members, oversee by managing director and working in partnership with international NGOs and embassies, private sectors and project beneficiaries at all level since 2019. </li>
                <li>In the year 2022 HAIDO has excellent record of accomplishment on emergency humanitarian assistance for conflict Affected people through provision of food and core relief items in zone two Berhale, koneba and Dallol for 3500 HH and cash support cash based intervention for 240 most vulnerable house hold beneficiaries in Afar regional state of Ethiopia</li>
              </ul>
            </div>
         </div>
      </div>
      <Footer/>
    </div>
    </>
  )
}

export default About
