import React from 'react'
import './events.scss'
import {MdOutlineSubtitles} from 'react-icons/md'
import {GrDocumentUpdate} from 'react-icons/gr'
import IMG from '../../assets/haido10.jpg'
import IMG2 from '../../assets/haido13.jpg'
import IMG3 from '../../assets/haido4.jpg'
import IMG4 from '../../assets/haido2.jpg'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const data = [
    {
     id: 1,
     img: IMG,
     alt: 'image-one',
     figurecaption: `Fig.1-dfhhdfsdbadkfjhsfdshfhhffhsfj`,
     title: `Bubbe's Book Club`,
     info: 'Bellmore, NY',
     info2: 'Grand Central Terminal',
     info3: 'Sat, Sep 19, 10:00 AM EDT',
     description: ` Welcome! Everyone has a unique 
                   perspective after reading a book, 
                   and we would love you
                   to share yours with us! 
                   We meet one Sunday evening`,
    },
    {
        id: 2,
        img: IMG2,
        alt: 'image-one',
        figurecaption: `Fig.1-dfhhdfjdfsdbjhsfdshfhhffhsfj`,
        title: `Bubbe's Book Club`,
        info: 'Bellmore, NY',
        info2: 'Grand Central Terminal',
        info3: 'Sat, Sep 19, 10:00 AM EDT',
        description: ` Welcome! Everyone has a unique 
                      perspective after reading a book, 
                      and we would love you
                      to share yours with us! 
                      We meet one Sunday evening`,
       },
       {
        id: 3,
        img: IMG3,
        alt: 'image-one',
        figurecaption: `Fig.1-dfhhdfjdfsjhsfdshfhhffhsfj`,
        title: `Bubbe's Book Club`,
        info: 'Bellmore, NY',
        info2: 'Grand Central Terminal',
        info3: 'Sat, Sep 19, 10:00 AM EDT',
        description: ` Welcome! Everyone has a unique 
                      perspective after reading a book, 
                      and we would love you
                      to share yours with us! 
                      We meet one Sunday evening`,
       },
       {
        id: 4,
        img: IMG4,
        alt: 'image-one',
        figurecaption: `Fig.1-dfhhdfjdfsdbadkfjhsfdshfhhffhsfj`,
        title: `Bubbe's Book Club`,
        info: 'Bellmore, NY',
        info2: 'Grand Central Terminal',
        info3: 'Sat, Sep 19, 10:00 AM EDT',
        description: ` Welcome! Everyone has a unique 
                      perspective after reading a book, 
                      and we would love you
                      to share yours with us! 
                      We meet one Sunday evening`,
       },
]

const Events = () => {
  return (
    <>
    <Navbar/>
    <div className='body'>
         <div className='home-body'>
            <h2>Events</h2>
            <p>currently no events </p>
         </div>
    
    {/*<div className='event-container'>
      {
        data.map(({id, img, alt, title, info, info2, info3, description, figurecaption})=>{
          return(
           <div key={id} className='card-container'>
              <figure className='img-container'>
                 <img src={img} alt={alt}/>
                 <figcaption>{figurecaption}</figcaption>
              </figure>
              <div className='body-container'>
                 <div className='overlay'></div>
                 <div className='event-info'>
                    <p className='title'>{title}</p>
                    <div className='separator'></div>
                    <p className='info'>{info}</p>
                    <div className='additional-info'>
                        <p className='info'>
                            <MdOutlineSubtitles className='i'/>
                            {info2}
                        </p>
                        <p className='info'>
                            <GrDocumentUpdate className='i'/>
                            {info3}
                        </p>
                        <p className='info description'>
                           {description}
                        </p>
                    </div>
                 </div>
              </div>
           </div>
          );
         })
        }
    </div>*/}
    </div>
    <Footer/>
    </>
  )
}

export default Events
