import React from 'react'
import './imgslide.scss'
import styled from 'styled-components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import img1 from '../../assets/haido13.jpg'
import img2 from '../../assets/haido3.jpg'
import img4 from '../../assets/haido12.jpg'
import img3 from '../../assets/haido4.jpg'
import img5 from '../../assets/haido01.jpg'
import img6 from '../../assets/haido02.jpg'
import img7 from '../../assets/haido03.jpg'
import img8 from '../../assets/haido04.jpg'

function ImgSlider() {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
  return (

    <Carousel {...settings}>
      <AliceCarousel autoPlay infinite autoPlayInterval={3000} animationDuration={3000}>
        <Wrap>
            <img src={img1} alt='slide image1'/>
        </Wrap>
        <Wrap>
            <img src={img2} alt='slide image2'/>
        </Wrap>
        <Wrap>
            <img src={img3} alt='slide image3'/>
        </Wrap>
        <Wrap>
            <img src={img4} alt='slide image4'/>
        </Wrap>
        <Wrap>
            <img src={img5} alt='slide image5'/>
        </Wrap>
        <Wrap>
            <img src={img6} alt='slide image6'/>
        </Wrap>
        <Wrap>
            <img src={img7} alt='slide image7'/>
        </Wrap>
        <Wrap>
            <img src={img8} alt='slide image8'/>
        </Wrap>
        </AliceCarousel>
    </Carousel>
  )
}

export default ImgSlider;

const Carousel = styled(Slider)`
  margin-top: 20px;
  margin: 0;
  height: 100%;
  width: 100%;

`
const Wrap = styled.div`
  cursor: pointer;
  z-index: 1;
  img{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
    transition-duration: 300ms;
  }
`

