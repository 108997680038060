import React  from "react";
import './footer.scss';
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'
import { Link } from "react-router-dom";


const Footer = () => {
    return (
        <footer>
            <a href="#" className="footer_logo">HAIDO</a>
            <ul className="permanentlinks">
               <li><Link to="/home">Home</Link></li>
               <li><Link to="/about">About</Link></li>
               <li><Link to="/galary">Galary</Link></li>
               <li><Link to="/event">Event</Link></li>
               <li><Link to="/news">News</Link></li>
               <li><Link to="/contact">Contact Us</Link></li>
            </ul>
            <div className="footer_socials">
               <a href="#"><FaFacebookF/></a>
               <a href="#"><FiInstagram/></a>
                <a href="#"><IoLogoTwitter/></a>
                
            </div>
            
            <div className="footer_copyright">
               <a href="https://www.arhotechnology.com"> <small>&copy; Arho Technology. All rights reserved</small></a>
            </div>
        </footer>
    )
}

export default Footer;