import React from 'react'
import img1 from '../../assets/haido3.jpg'
import img2 from '../../assets/haido4.jpg'
import img3 from '../../assets/haido11.jpg'
import img4 from '../../assets/haido10.jpg'
import img5 from '../../assets/haido18.jpg'
import img6 from '../../assets/haido5.jpg'
import './weworkon.scss'

const WeWorkOn = () => {
  return (
    <div className='work-container'>
      <div className='work-vission'>
           <h3>Vission</h3>
            <p> Help Africa integrated development organisation-HAIDO works in Africa to save lives of vulnerable people through provisionof basic needs, tackle poverty, and achieve social justice.</p>
         </div>
      <div className='work-box'>
         <div className='box-1'>
           <h3>Mission</h3>
            <ul>
              <li>To Address Basic need to disaster affected people everywhere in Africa  through provision of basic humanitarian service</li>
              <li>To overcome endless poverty </li>
            </ul>
         </div>
         <div className='box-2'>
            <h3>Value</h3>
            <p>
            Integrity, and solidarity, Accountability, Trust worthiness, Transparency, Participation and networking, Gender responsiveness, Self reliance , Commitment and Community participation </p>
         </div>
      </div>

      <div className='work-title'>
        <span></span>
        <h1>How we fight poverty and inequality</h1>
      </div>

      <div className='work-on'>
        <article>
            <img src={img1} alt=''/>
            <div className='icon-text'>
              <i>|||</i>
              <span>Emergency aid</span>
            </div>
        </article>
        <article>
            <img src={img2} alt=''/>
            <div className='icon-text'>
              <i>|||</i>
              <span>Emergency aid</span>
            </div>
        </article>
        <article>
            <img src={img3} alt=''/>
            <div className='icon-text'>
              <i>|||</i>
              <span>Emergency aid</span>
            </div>
        </article>
        <article>
            <img src={img4} alt=''/>
            <div className='icon-text'>
              <i>|||</i>
              <span>Emergency aid</span>
            </div>
        </article>
        <article>
            <img src={img5} alt=''/>
            <div className='icon-text'>
              <i>|||</i>
              <span>Emergency aid</span>
            </div>
        </article>
        <article>
            <img src={img6} alt=''/>
            <div className='icon-text'>
              <i>|||</i>
              <span>Emergency aid</span>
            </div>
        </article>
      </div>
    </div>
  )
}

export default WeWorkOn;
